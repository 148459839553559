import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Mettre ses assets en cache avec Jekyll",
  "description": "Cet article montre une astuce toute simple que j’utilise pour contourner les problèmes de cache que l’on peut rencontrer sur Jekyll avec différents assets.",
  "date": "2014-12-17T00:00:00.000Z",
  "path": "/articles/mettre-ses-assets-en-cache-avec-jekyll/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Je partage aujourd’hui une solution assez simple que l’on peut mettre en place avec Jekyll pour générer une unique ID utilisable sur nos assets pour contourner les éventuels problèmes de cache, et ainsi faire en sorte que les différents assets que l’on utilise soient correctement mis à jour.`}</p>
    <p className="info">
  Mise à jour (01/07/2015) : depuis l’écriture de cette article, j’ai mis en
  place un système bien plus pertinent que je détaille ci-dessous.
    </p>
    <h2>{`L’ancienne solution`}</h2>
    <p>{`Celle-ci se base sur les formats date du système de templating `}<a href="http://liquidmarkup.org/">{`liquid`}</a>{` pour générer une ID. Ainsi, au lieu de :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "ruby"
      }}><code parentName="pre" {...{
          "className": "ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`link rel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"stylesheet"`}</span></span>{` href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"/css/myawesomestylesheet.css"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Il nous suffira d’utiliser :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "ruby"
      }}><code parentName="pre" {...{
          "className": "ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`link rel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"stylesheet"`}</span></span>{` href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"/css/myawesomestylesheet.css?v={{ site.time | date: '%y%m%d%k%M%S' }}"`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Ce qui générera, dans l’ordre : l’année, le mois, le jour, et l’heure avec minutes et secondes. Ainsi le résultat obtenu sera de ce type :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`link`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`rel`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`stylesheet`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/css/myawesomestylesheet.css?v=141217192112`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <p>{`De cette manière, on est sûr d’avoir une ID `}<em parentName="p">{`presque`}</em>{` unique puisqu’à chaque seconde passée elle sera différente.`}</p>
    <h2>{`Les limites de cette solution`}</h2>
    <p>{`En appliquant cette façon de faire, tous les fichiers HTML seront mis à jour avec une nouvelle version du CSS, même si celui-ci n’a pas changé. La mise en cache perd un peu de son intérêt... Autre argument, Google `}<a parentName="p" {...{
        "href": "https://developers.google.com/speed/docs/insights/LeverageBrowserCaching"
      }}>{`conseille`}</a>{` d’éviter les `}<em parentName="p">{`query parameters`}</em>{` au profit des `}<em parentName="p">{`fingerprints`}</em>{`.`}</p>
    <h2>{`Un autre système`}</h2>
    <p>{`Je suis allé chercher du côté des plugins Jekyll et j’ai déniché `}<a parentName="p" {...{
        "href": "https://github.com/tkareine/jekyll-minibundle"
      }}>{`Jekyll-minibundle`}</a>{`, qui fait exactement ce que je cherche. Une fois le plugin installé, les `}<em parentName="p">{`fingerprints`}</em>{` s’ajoutent de cette manière :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "ruby"
      }}><code parentName="pre" {...{
          "className": "ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`link href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"{% ministamp /_assets/css/fox.css /assets/css/fox.css %}"`}</span></span>{` rel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"stylesheet"`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Le premier lien est le fichier source, le deuxième est le fichier de destination. Le code généré donne ça :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`link`}</span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/assets/css/fox-86e4c5e39ca8a41719996d783a87c702.css`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`rel`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`stylesheet`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      